import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { Auth } from "aws-amplify";
import { HelperService, UserService, environment } from "@mypxplat/xplat/core";
import { HttpRequest } from "@angular/common/http";
import { AppService } from "@mypxplat/xplat/web/core";
import moment from "moment";
import { Router } from "@angular/router";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  constructor(private helperService: HelperService, private userService: UserService, private appService: AppService, public router: Router) {}

  authenticate(request): Observable<HttpRequest<any>> {
    return new Observable((observer) => {
      Auth.currentSession()
        .then((session) => {
          this.helperService.accessToken = session.getAccessToken().getJwtToken();
          this.helperService.idToken = session.getIdToken().getJwtToken();
          this.helperService.refreshToken = session.getRefreshToken().getToken();
          this.appService.setXplatformCookies(this.helperService.accessToken, this.helperService.idToken, this.helperService.refreshToken);
          const authenticatedRequest = request.clone(this.getHeaders(session));
          observer.next(authenticatedRequest);
          observer.complete(); // Complete the observable
        })
        .catch((err) => {
          if (err === "No current user") {
            this.appService.setTokensFromCookies();
            if (this.appService.signInFromSavedTokens()) {
              Auth.currentSession()
                .then((newSession) => {
                  this.helperService.accessToken = newSession.getAccessToken().getJwtToken();
                  this.helperService.idToken = newSession.getIdToken().getJwtToken();
                  this.helperService.refreshToken = newSession.getRefreshToken().getToken();
                  const authenticatedRequest = request.clone(this.getHeaders(newSession));
                  observer.next(authenticatedRequest);
                  observer.complete();
                })
                .catch((newErr) => {
                  // console.log("didnt work", newErr);
                  this.handleAuthError();
                  observer.error(newErr);
                });
            } else {
              this.handleAuthError();
              observer.error(err);
            }
          } else {
            this.handleAuthError();
            observer.error(err);
          }
        });
    });
  }

  public handlingAuthError: boolean = false;
  handleAuthError() {
    if (!this.handlingAuthError) {
      this.handlingAuthError = true;
      if (this.router.url == "/logout") {
        this.appService.redirectToConnect(false, true);
      } else {
        this.router.navigate(["/logout"]);
      }
    }
  }

  getHeaders(session) {
    const accessToken = session.accessToken.jwtToken;
    const refreshToken = session.refreshToken.token;
    const idToken = session.idToken.jwtToken;
    const tokenExpiry = session.idToken.payload.exp;
    this.helperService.accessToken = accessToken;
    this.helperService.idToken = idToken;
    this.helperService.tokenExpiry = tokenExpiry;
    this.helperService.refreshToken = refreshToken;
    this.helperService.token = idToken;
    // console.log("%cTOKEN: " + idToken.substr(idToken.length - 5) + " | EXPIRES: " + moment.unix(session.idToken.payload.exp).format("HH:mm:ss"), "color: #0088cc;");
    return {
      setHeaders: {
        "Content-Type": "application/json",
        "X-API-KEY": environment.presonusApiKey,
        "X-USER-TOKEN": idToken,
      },
    };
  }
}
