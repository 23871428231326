import { NgModule, Injectable } from "@angular/core";
import { AngularFireAuth } from "@angular/fire/compat/auth";
import { RouterModule, Routes, CanActivate, Router, ActivatedRoute } from "@angular/router";
import { environment, HelperService, StorageService, UserService } from "@mypxplat/xplat/core";
import { AppService, FirebaseService } from "@mypxplat/xplat/web/core";
import { CookieService } from "ngx-cookie-service";
import { take } from "rxjs/operators";
import { SharedModule } from "./features/shared/shared.module";

@Injectable()
export class AuthGuardService implements CanActivate {
  constructor(
    public router: Router,
    public activatedRoute: ActivatedRoute,
    public storage: StorageService,
    public userService: UserService,
    public helperService: HelperService,
    public cookieService: CookieService,
    public appService: AppService,
    public fbAuth: AngularFireAuth,
    public fbService: FirebaseService
  ) {}
  canActivate(): Promise<any> {
    return new Promise((resolve, reject) => {
      let canGo = false;
      this.appService.setTokensFromCookies();
      if (!this.helperService.token) {
        if (location.pathname == "/signup") {
          canGo = true;
        } else {
          const dontRedirectPathMap = {
            "/": true,
            login: true,
            "/signup": true,
            "/welcome": true,
          };
          if (location.pathname && !dontRedirectPathMap[location.pathname]) {
            this.appService.redirectToConnect(true);
          } else {
            this.appService.redirectToConnect(false, true);
          }
          canGo = false;
        }
      } else {
        canGo = true;
      }
      if (canGo) {
        this.fbAuth.user.pipe(take(1)).subscribe({
          next: (result) => {
            this.fbService.firbeaseUserLoggedIn$.next(true);
            if (this.userService.user && result && result.uid == this.userService.user?.id) {
              this.appService.initApp();
              resolve(true);
            } else {
              this.userService.getUserDetails(false).subscribe({
                next: (result) => {
                  this.fbService
                    .loginFirebaseUser()
                    .then(() => {
                      this.appService.initApp();
                      resolve(true);
                    })
                    .catch((e) => {
                      this.appService.redirectToConnect(false, true);
                      resolve(false);
                    });
                },
                error: (err) => {
                  this.appService.redirectToConnect(false, true);
                },
              });
            }
          },
          error: (err) => {
            // console.log("app.component.ts:174 firebase signout error", err);
          },
        });
      } else {
        this.appService.redirectToConnect(false, true);
        resolve(false);
      }
    });
  }
}
const routes: Routes = [
  { path: "", redirectTo: "/home", pathMatch: "full" },
  { path: "content", redirectTo: "/learn/browse", pathMatch: "full" },
  { path: "login", loadChildren: () => import("./features/login-page/login-page.module").then((m) => m.LoginPageModule) },
  { path: "video/:type/:id", loadChildren: () => import("./features/video-page/video-page.module").then((m) => m.VideoPageModule) },
  { path: "share/:hash", loadChildren: () => import("./features/share-page/share-page.module").then((m) => m.SharePageModule) },
  { path: "auth/reset_password/:code", loadChildren: () => import("./features/login-page/login-page.module").then((m) => m.LoginPageModule) },
  { path: "auth/activate/:user_id/:activate_code", loadChildren: () => import("./features/login-page/login-page.module").then((m) => m.LoginPageModule) },
  { path: "auth/login", loadChildren: () => import("./features/login-page/login-page.module").then((m) => m.LoginPageModule) },
  { path: "auth/forgot_password", loadChildren: () => import("./features/login-page/login-page.module").then((m) => m.LoginPageModule) },
  { path: "forgot_password", loadChildren: () => import("./features/login-page/login-page.module").then((m) => m.LoginPageModule) },
  { path: "amazon/register", loadChildren: () => import("./features/login-page/login-page.module").then((m) => m.LoginPageModule) },
  { path: "auth/login/marketplace", loadChildren: () => import("./features/login-page/login-page.module").then((m) => m.LoginPageModule) },
  { path: "logout", loadChildren: () => import("./features/login-page/login-page.module").then((m) => m.LoginPageModule) },
  { path: "welcome", loadChildren: () => import("./features/welcome-page/welcome-page.module").then((m) => m.WelcomePageModule) },
  { path: "signup", redirectTo: "/onboarding", pathMatch: "full" },
  { path: "createaccount", redirectTo: "/onboarding", pathMatch: "full" },
  { path: "register", redirectTo: "/onboarding?registerProduct=true", pathMatch: "full" },
  { path: "join", redirectTo: "/onboarding?subscribeOnly=true", pathMatch: "full" },
  { path: "buy", redirectTo: "/checkout?checkoutProductType=Perpetual", pathMatch: "full" },
  { path: "checkout", loadChildren: () => import("./features/checkout-page/checkout-page.module").then((m) => m.CheckoutPageModule), canActivate: [AuthGuardService] },
  { path: "onboarding", loadChildren: () => import("./features/onboarding-page/onboarding-page.module").then((m) => m.OnboardingPageModule), canActivate: [AuthGuardService] },
  { path: "home", loadChildren: () => import("./features/home-page/home-page.module").then((m) => m.HomePageModule), canActivate: [AuthGuardService] },
  { path: "account", loadChildren: () => import("./features/account-page/account-page.module").then((m) => m.AccountPageModule), canActivate: [AuthGuardService] },
  { path: "workspaces", loadChildren: () => import("./features/workspaces-page/workspaces-page.module").then((m) => m.WorkspacesPageModule), canActivate: [AuthGuardService] },
  { path: "messages", loadChildren: () => import("./features/messages-page/messages-page.module").then((m) => m.MessagesPageModule), canActivate: [AuthGuardService] },
  { path: "profile", loadChildren: () => import("./features/profile-page/profile-page.module").then((m) => m.ProfilePageModule), canActivate: [AuthGuardService] },
  { path: "metro", loadChildren: () => import("./features/satellite-page/satellite-page.module").then((m) => m.SatellitePageModule), canActivate: [AuthGuardService] },
  { path: "products", loadChildren: () => import("./features/products-page/products-page.module").then((m) => m.ProductsPageModule), canActivate: [AuthGuardService] },
  { path: "exchange", loadChildren: () => import("./features/exchange-page/exchange-page.module").then((m) => m.ExchangePageModule), canActivate: [AuthGuardService] },
  { path: "community", loadChildren: () => import("./features/community-page/community-page.module").then((m) => m.CommunityPageModule), canActivate: [AuthGuardService] },
  { path: "beta", loadChildren: () => import("./features/beta-page/beta-page.module").then((m) => m.BetaPageModule), canActivate: [AuthGuardService] },
  { path: "support", loadChildren: () => import("./features/support-page/support-page.module").then((m) => m.SupportPageModule), canActivate: [AuthGuardService] },
  { path: "events", loadChildren: () => import("./features/events-page/events-page.module").then((m) => m.EventsPageModule), canActivate: [AuthGuardService] },
  { path: "orders", loadChildren: () => import("./features/orders-page/orders-page.module").then((m) => m.OrdersPageModule), canActivate: [AuthGuardService] },
  // { path: "admin", loadChildren: () => import("./features/admin-page/admin-page.module").then((m) => m.AdminPageModule), canActivate: [AuthGuardService] },
  { path: "thankyou", loadChildren: () => import("./features/home-page/home-page.module").then((m) => m.HomePageModule), canActivate: [AuthGuardService] },
  { path: "learn", loadChildren: () => import("./features/education-page/education-page.module").then((m) => m.EducationPageModule), canActivate: [AuthGuardService] },
  { path: "managebetas", loadChildren: () => import("./features/managebetas/managebetas.module").then((m) => m.ManagebetasModule) },
  { path: "education", loadChildren: () => import("./features/education-page/education-page.module").then((m) => m.EducationPageModule), canActivate: [AuthGuardService] },
  { path: "dealer", loadChildren: () => import("./features/dealer-page/dealer-page.module").then((m) => m.DealerPageModule) },
  { path: "studioonedemo", loadChildren: () => import("./features/demo-signup-page/demo-signup-page.module").then((m) => m.DemoSignupPageModule) },
  { path: "**", loadChildren: () => import("./features/notfound-page/notfound-page.module").then((m) => m.NotfoundPageModule) },
  // { path: "**", redirectTo: "/home" },
];

const checkoutRoutes: Routes = [
  { path: "join", redirectTo: "/checkout", pathMatch: "full" },
  { path: "buy", redirectTo: "/checkout?checkoutProductType=Perpetual", pathMatch: "full" },
];

const modalCheckoutRoutes: Routes = [
  { path: "join", loadChildren: () => import("./features/signup-page/signup-page.module").then((m) => m.SignupPageModule) },
  { path: "buy", loadChildren: () => import("./features/signup-page/signup-page.module").then((m) => m.SignupPageModule) },
];

@NgModule({
  imports: [SharedModule, RouterModule.forRoot(environment.features.checkout_page ? [...routes, ...checkoutRoutes] : [...routes, ...modalCheckoutRoutes])],
  providers: [AuthGuardService],
  exports: [RouterModule],
  declarations: [],
})
export class AppRoutingModule {}
